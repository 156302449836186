<template>
  <list-template
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :current-page="page"
      :loading="loading"
      has-add
      @onAdd="$router.push('./add')"
      @onChangePage="changeCurrentPage"
      @onHandle="tableHandle"
  ></list-template>
</template>

<script>
//引入ajax接口管理层的接口函数
import { classroomList, classroomDel } from '@/api/school'
import {mapState} from "vuex"
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
		return {
      loading:true,
			tableData: [],
			total: 0,
      tableConfig:[
        {
          prop:"classroom_name",
          label:"教室名称"
        },
        {
          prop:"school_name",
          label:"所属校区"
        },
        {
          prop:"max_number",
          label:"最大容纳人数",
          width:120
        },
        {
          prop:"dimicile_buliding_name",
          label:"所属教学楼"
        },
        {
          prop:"storey",
          label:"楼层",
          width:80
        },
        {
          handle:true,
          width:150,
          label:"操作",
          buttons: [{ type:"edit", text:"编辑" },{ type:"delete", text:"删除" }]
        },
      ]
		}
	},
  computed:{
    ...mapState(["page"])
  },
	methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit("setPage",e);
      this.getData();
    },
		getData() {
			let page = this.page;
      this.loading = true;
			classroomList(page).then(res => {
				this.tableData = res.data.data
				let { headers } = res
				let date = this.$tools.getPaginationInfo(headers)
				this.total = date.total
        this.$store.commit("setPage",date.page);
			}).finally(()=>this.loading = false);
		},
    tableHandle(row,handle){
      const { type } = handle
      if(type === "delete") {
        this.$confirm('删除后，会影响其他功能使用，是否删除？', {
            confirmButtonText: '删除',
            cancelButtonText: '点错了',
            type: 'warning'
          }).then(() => {
            classroomDel({ id:row.id }).then(res => {
              if (res.data.error.errorCode == 0) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                this.getData()
              } else {
                this.$message({
                  type: 'error',
                  message: '删除失败'
                })
              }
            })
          });
      } else if(type === "edit") {
        this.$router.push('./edit?id=' + row.id);
      }
    }
	},
	activated() {
		this.getData()
  },
	mounted() {
    this.$store.commit("setPage",1);
		this.getData()
	}
}
</script>

<style lang="scss" scoped>
</style>
