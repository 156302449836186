/**
 * 子管理员账号管理模块
 */

import request from '@/utils/request'

// 公共方法
// 楼宇获取
export const building = (id) => {
  return request({
    method: 'get',
    url: '/site/buliding?school_id='+id,
  })
}


//get 请求
/* 获取教室列表 */
export const classroomList = (page = 1) => {
  return request({
    method: 'get',
    url: '/classroom/list' + '?page=' + page,
  })
}

// 删除教室
export const classroomDel = (data) => {
  return request({
    method: 'post',
    url: '/classroom/del',
    data
  })
}

// 新增教室
export const classroomAdd = (data) => {
  return request({
    method: 'post',
    url: '/classroom/add',
    data
  })
}

// 教室详情
export const classroomDetail = (data) => {
  return request({
    method: 'post',
    url: '/classroom/detail',
    data
  })
}

// 教室详情
export const classroomEdit = (data) => {
  return request({
    method: 'post',
    url: '/classroom/edit',
    data
  })
}